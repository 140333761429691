import React, { useState } from "react";

const SearchBar = ({ onClose, onSearch, setSearchTerm }) => {
  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    setSearchTerm(e.target.value); // Set search term in parent component
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      onSearch();
      setSearchInput(""); // Clear input field after searching
    }
  };

  return (
    <div className="search-bar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="28"
        height="28"
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="search-icon"
        style={{ transition: "stroke 0.3s ease, box-shadow 0.3s ease" }}
        onMouseOver={(e) => {
          e.currentTarget.style.stroke = "";
          e.currentTarget.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
          e.currentTarget.style.cursor = "pointer";
        }}
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
      <input
        type="text"
        placeholder="Search our store"
        style={{ fontFamily: "Conduit Itc" }}
        value={searchInput}
        onChange={handleInputChange}
        onKeyPress={handleEnterKey}
      />
      <button
        className="close-button"
        onClick={() => {
          onClose();
          setSearchInput(""); // Clear input field when closing
        }}
      >
        &#10006;
      </button>
      {/* No need for a search button */}
      {/* Add any other elements or styling for the search bar */}
    </div>
  );
};

export default SearchBar;
