import React from "react";
import "./globalstyles.css";
import Home from "../pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenSection from "../pages/MenSection";
import WomenSection from "../pages/WomenSection";
import menCardsData from "./menCardsData";
import MenCards from "./MenCards";
import ProductDetailPage from "./ProductDetailPage";
import SingleCard from "../pages/SIngleCard";
import ComingSoon from "./ComingSoon";
import SingleCardW from "../pages/SingleCardW";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            exact
            path="/men"
            render={() => <MenCards cardData={menCardsData} />}
          />
          <Route path="/product-women/:productId" element={<SingleCardW />} />
          <Route path="/product/:productId" element={<SingleCard />} />
          <Route path="/men-section" element={<MenSection />} />
          <Route path="/women-section" element={<WomenSection />} />
        </Routes>
      </Router>
    </div>
  );
}
