import React from "react";
import Women from "../components/Women";
import NewArrival from "../components/NewArrival";
import Men from "../components/Men";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div>
      <Hero />
      <NewArrival />
      <Men />
      <Women />
      <Footer />
    </div>
  );
}
