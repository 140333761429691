import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import menCardsData from "./menCardsData";
import MenCards from "./MenCards";

import "./Hero.css";

export default function Hero() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedJacket, setSearchedJacket] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const closeSearchBar = () => {
    setSearchOpen(false);
  };

  const handleSearch = () => {
    // Perform search logic based on the jacket name
    const result = menCardsData.find(
      (jacket) => jacket.name.toLowerCase() === searchTerm.toLowerCase()
    );
    console.log("Search Term:", searchTerm);
    console.log("Jackets Data:", menCardsData);
    console.log("Search Result:", result);
    setSearchedJacket(result);
    console.log(searchedJacket);
  };

  return (
    <div className="hero">
      <video autoPlay muted loop playsInline className="back-video">
        <source src={require("../images/girl.mp4")} />
      </video>
      <nav>
        <div className="logo-container">
          <img
            src={require("../images/ct-logo.png")}
            alt="logo"
            className="logo"
          />
        </div>
        <ul className={`menu menu-container ${isMenuOpen ? "show" : ""}`}>
          <div
            className={`cross-icon ${isMenuOpen ? "show" : ""}`}
            onClick={toggleMenu}
          >
            &#10006;
          </div>
          <li className="him-one">
            <Link to="men-section">MEN'S leather jackets</Link>
            <div
              style={{
                backgroundColor: "white",
                marginBottom: "0",
                marginLeft: "0",
              }}
            ></div>
          </li>
          <li>
            <Link to="women-section">Women's leather jackets</Link>
          </li>
          <li className="him">
            <a href="#">MEN'S sheepskin jackets</a>
          </li>
          <li>
            <a href="#">Women's sheepskin jackets</a>
          </li>
          <li className="him">
            <a href="#">Suede jackets</a>
          </li>
          <li>
            <a href="#">black leather jackets</a>
          </li>
          <li className="him">
            <a href="#">Our leather</a>
          </li>
          <li>
            <a href="#">FAQ</a>
          </li>
        </ul>
        <div className="icons-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="search-icon"
            style={{ transition: "stroke 0.3s ease, box-shadow 0.3s ease" }}
            onMouseOver={(e) => {
              e.currentTarget.style.stroke = "";
              e.currentTarget.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.cursor = "pointer";
            }}
            onClick={(e) => {
              e.preventDefault();
              toggleSearch();
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.stroke = "white";
              e.currentTarget.style.boxShadow = "none";
              e.currentTarget.style.cursor = "default";
            }}
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="shopping-cart-icon"
          >
            <circle cx="9" cy="21" r="1"></circle>
            <circle cx="20" cy="21" r="1"></circle>
            <path d="M1 1h4l2.64 12.71a1.992 1.992 0 0 0 1.96 1.58h8.8a1.992 1.992 0 0 0 1.96-1.58L22 6H6"></path>
          </svg>
          <div
            className={`container1 ${isMenuOpen ? "change" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar11"></div>
            <div className="bar22"></div>
            <div className="bar33"></div>
          </div>
        </div>
      </nav>
      <div className="content">
        <h3>ChamraTraash</h3>
        <h3 className="leather">LeatherJackets</h3>
        <Link style={{ marginRight: "5px" }} to="men-section">
          <div style={{ transform: "skew(20deg)" }}> FOR HIM</div>
        </Link>
        <Link to="women-section">
          <div style={{ transform: "skew(20deg)" }}>FOR HER</div>
        </Link>
      </div>
      <div className={`search-container ${isSearchOpen ? "show" : ""}`}>
        <SearchBar
          onClose={closeSearchBar}
          onSearch={handleSearch}
          setSearchTerm={setSearchTerm}
        />
      </div>
    </div>
  );
}
