import React from "react";
import HeroForMen from "../components/HeroForMen";
import Footer from "../components/Footer";
import ProductDetailPage from "../components/ProductDetailPage";

export default function SingleCard() {
  return (
    <div>
      <HeroForMen />
      <ProductDetailPage />
      <Footer />
    </div>
  );
}
