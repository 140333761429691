import React, { useState } from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

export default function HeroForMen() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    console.log("button triggered");
  };
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const closeSearchBar = () => {
    setSearchOpen(false);
  };
  return (
    <div className="hero-contain">
      <nav
        style={{
          background: "linear-gradient(135deg, #f2f2f2, #e0e0e0)",
          color: "#333",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div className="logo-container">
          <img
            style={{ width: "50px" }}
            src={require("../images/title-logo.png")}
            alt="logo"
            className="logo"
          />
        </div>
        <ul className={`menu menu-container ${isMenuOpen ? "show" : ""}`}>
          <div
            className={`cross-icon ${isMenuOpen ? "show" : ""}`}
            onClick={toggleMenu}
          >
            &#10006;
          </div>
          <div className={`search-container ${isSearchOpen ? "show" : ""}`}>
            <SearchBar onClose={closeSearchBar} />
          </div>
          <li className="him-one">
            <Link to="men-section">MEN'S leather jackets</Link>
          </li>
          <li>
            <Link to="women-section">Women's leather jackets</Link>
          </li>
          <li className="him">
            <a href="#">MEN'S sheepskin jackets</a>
          </li>
          <li>
            <a href="#">Women's sheepskin jackets</a>
          </li>
          <li className="him">
            <a href="#">Suede jackets</a>
          </li>
          <li>
            <a href="#">black leather jackets</a>
          </li>
          <li className="him">
            <a href="#">Our leather</a>
          </li>
          <li>
            <a href="#">FAQ</a>
          </li>
        </ul>
        <div className="icons-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="none"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="search-icon"
            style={{ transition: "stroke 0.3s ease, box-shadow 0.3s ease" }}
            onClick={(e) => {
              e.preventDefault();
              toggleSearch();
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.stroke = "";
              e.currentTarget.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.3)";
              e.currentTarget.style.cursor = "pointer";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.stroke = "black";
              e.currentTarget.style.boxShadow = "none";
              e.currentTarget.style.cursor = "default";
            }}
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="28"
            height="28"
            fill="none"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="shopping-cart-icon"
          >
            <circle cx="9" cy="21" r="1"></circle>
            <circle cx="20" cy="21" r="1"></circle>
            <path d="M1 1h4l2.64 12.71a1.992 1.992 0 0 0 1.96 1.58h8.8a1.992 1.992 0 0 0 1.96-1.58L22 6H6"></path>
          </svg>
          <div
            className={`container1 ${isMenuOpen ? "change" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </nav>
    </div>
  );
}
