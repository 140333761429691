const cardData = [
  {
    id: 1,
    name: "Rihanna Sheepskin Jacket",
    price: "$50",
    image: require("../images/women1.webp"),
    hoverImage: "",
  },
  {
    id: 2,
    name: "RIA hooded sheepskin jacket",
    price: "$60",
    image: require("../images/women2.jpeg"),
    hoverImage: "",
  },
  {
    id: 3,
    name: "Ramona tanned leather jacket",
    price: "$70",
    image: require("../images/women3.webp"),
    hoverImage: "",
  },
  {
    id: 4,
    name: "Renata black leather jacket",
    price: "$80",
    image: require("../images/women4.webp"),
    hoverImage: "",
  },
  {
    id: 5,
    name: "Rihanna Sheepskin Jacket",
    price: "$90",
    image: require("../images/women5.jpeg"),
    hoverImage: "",
  },
  {
    id: 6,
    name: "RIA hooded sheepskin jacket",
    price: "$100",
    image: require("../images/women6.webp"),
    hoverImage: "",
  },
  {
    id: 7,
    name: "Ramona tanned leather jacket",
    price: "$110",
    image: require("../images/women7.webp"),
    hoverImage: "",
  },
  {
    id: 8,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women8.webp"),
    hoverImage: "",
  },
  {
    id: 9,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women9.webp"),
    hoverImage: "",
  },
  {
    id: 10,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women10.jpeg"),
    hoverImage: "",
  },
  {
    id: 11,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women11.webp"),
    hoverImage: "",
  },
  {
    id: 12,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women12.webp"),
    hoverImage: "",
  },
  {
    id: 13,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women13.webp"),
    hoverImage: "",
  },
  {
    id: 14,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women14.jpeg"),
    hoverImage: "",
  },
  {
    id: 15,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/women15.jpeg"),
    hoverImage: "",
  },
];

export default cardData;
