import React from "react";
import womenVideo from "../images/women.mp4";
import { Link } from "react-router-dom";

export default function Women() {
  return (
    <div className="men-container">
      <video autoPlay muted loop playsInline className="background-video">
        <source src={womenVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay-text">
        <p>Chic Confidence in Every Stitch</p>
        <h5>Women's leather jackets</h5>
        <div className="shop-now-button-container">
          <Link to="women-section" className="shop-now-button">
            <div className="shop-woman">Shop Now</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
