import React from "react";
import HeroForMen from "../components/HeroForMen";
import MenCards from "../components/MenCards";
import Footer from "../components/Footer";

export default function MenSection() {
  return (
    <div>
      <HeroForMen />
      <MenCards />
      <Footer />
    </div>
  );
}
