// ProductDetailPage.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import menCardsData from "./menCardsData"; // Import menCardsData
import "./ProductDetailPage.css"; // Add your styles

const ProductDetailPage = () => {
  const [faqActive, setFaqActive] = useState(false);
  const [returnPolicyActive, setReturnPolicyActive] = useState(false);

  const [selectedSize, setSelectedSize] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    // Add any additional logic you need when a size is selected
  };
  const { productId } = useParams();
  const product = menCardsData.find((card) => card.id === parseInt(productId));

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleAddToCart = () => {
    // Implement your logic for adding to the cart
    console.log(`Added ${product.name} to the cart!`);
  };

  const toggleFAQ = () => {
    setFaqActive(!faqActive);
  };

  const toggleReturnPolicy = () => {
    setReturnPolicyActive(!returnPolicyActive);
  };
  return (
    <Container className="product-detail-page">
      <Row>
        {/* Product Image */}
        <Col xs={12} md={6}>
          <img
            src={product.image}
            alt={product.name}
            className="product-image"
          />
        </Col>

        {/* Product Description and Add to Cart */}
        <Col xs={12} md={6}>
          <div className="product-details">
            <div className="product-name">
              <h2>{product.name}</h2>
            </div>
            <h2 style={{ color: "white", fontSize: "1rem" }}>SIZE</h2>
            <div className="product-block">
              <div className="size-options">
                <button
                  data-size="XXS"
                  onClick={() => handleSizeSelect("XXS")}
                  className={selectedSize === "XXS" ? "selected" : ""}
                >
                  XXS
                </button>
                <button
                  data-size="XS"
                  onClick={() => handleSizeSelect("XS")}
                  className={selectedSize === "XS" ? "selected" : ""}
                >
                  XS
                </button>
                <button
                  data-size="S"
                  onClick={() => handleSizeSelect("S")}
                  className={selectedSize === "S" ? "selected" : ""}
                >
                  S
                </button>
                <button
                  data-size="M"
                  onClick={() => handleSizeSelect("M")}
                  className={selectedSize === "M" ? "selected" : ""}
                >
                  M
                </button>
                <button
                  data-size="L"
                  onClick={() => handleSizeSelect("L")}
                  className={selectedSize === "L" ? "selected" : ""}
                >
                  L
                </button>
                <button
                  data-size="XL"
                  onClick={() => handleSizeSelect("XL")}
                  className={selectedSize === "XL" ? "selected" : ""}
                >
                  XL
                </button>
                <button
                  data-size="2XL"
                  onClick={() => handleSizeSelect("2XL")}
                  className={selectedSize === "2XL" ? "selected" : ""}
                >
                  2XL
                </button>
              </div>
            </div>
            <p className="product-description">{/* Add description here */}</p>
            <div className="cart" onClick={handleAddToCart}>
              <div
                style={{
                  transform: "skew(20deg)",
                  textTransform: "uppercase",
                  cursor: "pointer",
                  color: "black",
                }}
              >
                Add to Cart
              </div>
            </div>
            <p className="text-cart">
              Be it monochrome or color blocking, there is something so vogue
              with brown shades! The Ramona leather jacket coming in a beautiful
              tanned hue is versatile clothing that can go song with many
              outfits. Be it a pretty floral dress or your savage denim, this
              leather jacket has the magic to make your outfit stand out in any
              crowd.<br></br>
              <br></br>Care and Use Instruction<br></br>
              <br></br>
              <ul>
                <li>Do not spot clean</li>
                <li>Do not Iron</li>
                <li>Specialist Lather Cleaner Only</li>
              </ul>
            </p>
            <div
              className={`faq-bar ${faqActive ? "active" : ""}`}
              onClick={toggleFAQ}
            >
              <div>
                FAQ{" "}
                <span className={`rotate ${faqActive ? "down" : ""}`}>▼</span>
              </div>
              <ul>
                <li>FAQ Item 1</li>
                <li>FAQ Item 2</li>
                {/* Add more FAQ items as needed */}
              </ul>
            </div>

            {/* Return Policy Bar */}
            <div
              className={`return-policy-bar ${
                returnPolicyActive ? "active" : ""
              }`}
              onClick={toggleReturnPolicy}
            >
              <div>
                Return Policy{" "}
                <span className={`rotate ${returnPolicyActive ? "down" : ""}`}>
                  ▼
                </span>
              </div>
              <ul>
                <li>Return Policy Item 1</li>
                <li>Return Policy Item 2</li>
                {/* Add more Return Policy items as needed */}
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetailPage;
