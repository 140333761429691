import React from "react";
import HeroForMen from "../components/HeroForMen";
import Footer from "../components/Footer";
import ProductDetailPageW from "../components/ProductDetailPageW";

export default function SingleCardW() {
  return (
    <div>
      <HeroForMen />
      <ProductDetailPageW />
      <Footer />
    </div>
  );
}
