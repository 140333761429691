import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NewArrival.css";
import "./MenCards.css";
import menCardsData from "./menCardsData";

const MenCards = ({ searchedJacket }) => {
  const calculateOriginalPrice = (discountedPrice) => {
    const price = parseInt(discountedPrice.slice(1));
    const discountPercentage = 20;
    const originalPrice = (price / (100 - discountPercentage)) * 100;
    return `$${originalPrice.toFixed(2)}`;
  };

  const calculateSavedAmount = (discountedPrice) => {
    const price = parseInt(discountedPrice.slice(1));
    const discountPercentage = 20;
    const savedAmount =
      (price / (100 - discountPercentage)) * discountPercentage;
    return savedAmount.toFixed(2);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="new-arrival" style={{ marginTop: "150px" }}>
      <h2 className="text-center mt-5 mb-5 text-white">FOR MEN</h2>
      <Container>
        <Row className="justify-content-md-center">
          {searchedJacket ? (
            <Col md={6} lg={3} className="mb-4">
              <Link
                to={`/product/${searchedJacket.id}`}
                className="product-link"
              >
                <div className="card-container">
                  <div className="image-container">
                    <img
                      style={{
                        width: "100%",
                        height: "350px",
                        objectFit: "cover",
                        transition: "opacity 0.3s ease-in-out",
                      }}
                      src={searchedJacket.image}
                      alt={searchedJacket.name}
                    />
                  </div>
                  <div className="text-container">
                    <h5
                      className="product-name"
                      style={{
                        letterSpacing: "0.2rem",
                        textDecoration: "none",
                      }}
                    >
                      {searchedJacket.name}
                    </h5>
                    <p className="price">
                      <div>
                        <p className="original-price">
                          {calculateOriginalPrice(searchedJacket.price)}
                        </p>
                        <p className="discounted-price">
                          {searchedJacket.price}
                        </p>
                        <p className="saved-amount">
                          Save ${calculateSavedAmount(searchedJacket.price)}
                        </p>
                      </div>
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
          ) : (
            menCardsData.map((card) => (
              <Col key={card.id} md={6} lg={3} className="mb-4">
                <Link to={`/product/${card.id}`} className="product-link">
                  <div className="card-container">
                    <div className="image-container">
                      <img
                        style={{
                          width: "100%",
                          height: "350px",
                          objectFit: "cover",
                          transition: "opacity 0.3s ease-in-out",
                        }}
                        src={card.image}
                        alt={card.name}
                      />
                      {card.hoverImage && (
                        <img
                          className="hover-image"
                          src={require(`../images/${card.hoverImage}`)}
                          alt={card.name}
                        />
                      )}
                    </div>
                    <div className="text-container">
                      <h5
                        className="product-name"
                        style={{
                          letterSpacing: "0.2rem",
                          textDecoration: "none",
                        }}
                      >
                        {card.name}
                      </h5>
                      <p className="price">
                        <div>
                          <p className="original-price">
                            {calculateOriginalPrice(card.price)}
                          </p>
                          <p className="discounted-price">{card.price}</p>
                          <p className="saved-amount">
                            Save ${calculateSavedAmount(card.price)}
                          </p>
                        </div>
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))
          )}
        </Row>
      </Container>
    </div>
  );
};

export default MenCards;
