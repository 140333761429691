const menCardsData = [
  {
    id: 1,
    name: "Rihanna Sheepskin Jacket",
    price: "$50",
    image: require("../images/men1.jpeg"),
    hoverImage: "",
  },
  {
    id: 2,
    name: "RIA hooded sheepskin jacket",
    price: "$60",
    image: require("../images/men2.jpeg"),
    hoverImage: "",
  },
  {
    id: 3,
    name: "Ramona tanned leather jacket",
    price: "$70",
    image: require("../images/men3.jpeg"),
    hoverImage: "",
  },
  {
    id: 4,
    name: "Renata black leather jacket",
    price: "$80",
    image: require("../images/men4.jpeg"),
    hoverImage: "",
  },
  {
    id: 5,
    name: "Rihanna Sheepskin Jacket",
    price: "$90",
    image: require("../images/men5.webp"),
    hoverImage: "",
  },
  {
    id: 6,
    name: "RIA hooded sheepskin jacket",
    price: "$100",
    image: require("../images/men6.webp"),
    hoverImage: "",
  },
  {
    id: 7,
    name: "Ramona tanned leather jacket",
    price: "$110",
    image: require("../images/men7.webp"),
    hoverImage: "",
  },
  {
    id: 8,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men8.webp"),
    hoverImage: "",
  },
  {
    id: 9,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men9.webp"),
    hoverImage: "",
  },
  {
    id: 10,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men10.jpeg"),
    hoverImage: "",
  },
  {
    id: 11,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men11.webp"),
    hoverImage: "",
  },
  {
    id: 12,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men12.jpeg"),
    hoverImage: "",
  },
  {
    id: 13,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men13.webp"),
    hoverImage: "",
  },
  {
    id: 14,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men14.jpeg"),
    hoverImage: "",
  },
  {
    id: 15,
    name: "Renata black leather jacket",
    price: "$120",
    image: require("../images/men15.webp"),
    hoverImage: "",
  },
];

export default menCardsData;
