import React, { useState } from "react";
import "./Footer.css";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <footer>
      <div className="footer-container">
        <div
          className={`footer-column ${
            activeDropdown === "support" ? "active" : ""
          }`}
        >
          <h3
            onClick={() => toggleDropdown("support")}
            className={activeDropdown === "support" ? "active" : ""}
          >
            SUPPORT AND FAQ'S
            <span
              className={`arrow-icon ${
                activeDropdown === "support" ? "rotate" : ""
              }`}
            >
              ▼
            </span>
          </h3>
          <ul className={activeDropdown === "support" ? "active" : ""}>
            <li>Search</li>
            <li>FAQs</li>
            <li>Our Leather</li>
            <li>Support</li>
            <li>Contact Us</li>
            <li>Sitemap</li>
            <li>Return & Exchange Policy</li>
            <li>Blog</li>
            <li>About Us</li>
          </ul>
        </div>

        <div
          className={`footer-column ${
            activeDropdown === "mainMenu" ? "active" : ""
          }`}
        >
          <h3
            onClick={() => toggleDropdown("mainMenu")}
            className={activeDropdown === "mainMenu" ? "active" : ""}
          >
            MAIN MENU
            <span
              className={`arrow-icon ${
                activeDropdown === "mainMenu" ? "rotate" : ""
              }`}
            >
              ▼
            </span>
          </h3>
          <ul className={activeDropdown === "mainMenu" ? "active" : ""}>
            <li>Men's Leather Jackets</li>
            <li>Women's Leather Jackets</li>
            <li>Men's Sheepskin Jackets</li>
            <li>Women's Sheepskin Jackets</li>
            <li>Suede Jackets</li>
            <li>Black Leather Jackets</li>
            <li>Our Leather</li>
            <li>FAQs</li>
            <li>Gift Vouchers</li>
            <li>New Arrivals</li>
            <li>Contact Us</li>
          </ul>
        </div>

        <div
          className={`footer-column ${
            activeDropdown === "legal" ? "active" : ""
          }`}
        >
          <h3
            onClick={() => toggleDropdown("legal")}
            className={activeDropdown === "legal" ? "active" : ""}
          >
            LEGAL
            <span
              className={`arrow-icon ${
                activeDropdown === "legal" ? "rotate" : ""
              }`}
            >
              ▼
            </span>
          </h3>
          <ul className={activeDropdown === "legal" ? "active" : ""}>
            <li>Cookie Policy</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
            <li>Returns & Refunds Policy</li>
          </ul>
        </div>

        <div
          className={`footer-column form-subscribe ${
            activeDropdown === "subscribe" ? "active" : ""
          }`}
        >
          <h3
            onClick={() => toggleDropdown("subscribe")}
            className={activeDropdown === "subscribe" ? "active" : ""}
          >
            SUBSCRIBE TO NEWSLETTER
            <span
              className={`arrow-icon ${
                activeDropdown === "subscribe" ? "rotate" : ""
              }`}
            >
              ▼
            </span>
          </h3>
          <form>
            <div className="custom-input">
              <input type="email" placeholder="Enter your email" />
              <div className="subscribe-button" type="submit">
                Subscribe
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* New div for the logo */}
      <div className="footer-logo">
        <img
          className="img-logo"
          src={require("../images/ct-logo.png")}
          alt="Logo"
        />
      </div>

      <div className="copyright">
        <p>© {currentYear} ChamraaTraash Leather Jackets</p>
      </div>
    </footer>
  );
}
