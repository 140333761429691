import React from "react";
import HeroForMen from "../components/HeroForMen";
import WomenCards from "../components/WomenCards";
import Footer from "../components/Footer";

export default function WomenSection() {
  return (
    <div>
      <HeroForMen />
      <WomenCards />
      <Footer />
    </div>
  );
}
