import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import "./NewArrival.css";

const NewArrival = () => {
  const calculateOriginalPrice = (discountedPrice) => {
    const price = parseInt(discountedPrice.slice(1));
    const discountPercentage = 20;
    const originalPrice = (price / (100 - discountPercentage)) * 100;
    return `$${originalPrice.toFixed(2)}`;
  };

  const calculateSavedAmount = (discountedPrice) => {
    const price = parseInt(discountedPrice.slice(1));
    const discountPercentage = 20;
    const savedAmount =
      (price / (100 - discountPercentage)) * discountPercentage;
    return savedAmount.toFixed(2);
  };

  const cardData = [
    {
      id: 1,
      name: "Rihanna Sheepskin Jacket",
      price: "$50",
      image: "leather1.avif",
      hoverImage: "leather_1.jpg",
    },
    {
      id: 2,
      name: "RIA hooded sheepskin jacket",
      price: "$60",
      image: "leather2.jpg",
      hoverImage: "401-logo.png",
    },
    {
      id: 3,
      name: "Ramona tanned leather jacket",
      price: "$70",
      image: "leather3.jpg",
      hoverImage: "leather_3.jpg",
    },
    {
      id: 4,
      name: "Renata black leather jacket",
      price: "$80",
      image: "leather4.avif",
      hoverImage: "leather_4.jpg",
    },
    {
      id: 5,
      name: "Rihanna Sheepskin Jacket",
      price: "$90",
      image: "leather5.jpg",
      hoverImage: "leather_5.jpg",
    },
    {
      id: 6,
      name: "RIA hooded sheepskin jacket",
      price: "$100",
      image: "leather6.jpg",
      hoverImage: "leather_6.jpg",
    },
    {
      id: 7,
      name: "Ramona tanned leather jacket",
      price: "$110",
      image: "leather7.jpg",
      hoverImage: "leather_7.jpg",
    },
    {
      id: 8,
      name: "Renata black leather jacket",
      price: "$120",
      image: "leather8.jpg",
      hoverImage: "leather_8.jpg",
    },
  ];

  const renderCards = (startIndex, endIndex) => {
    return cardData.slice(startIndex, endIndex).map((card) => (
      <Col key={card.id} xs={12} lg={3} className="mb-4">
        <div className="card-container">
          <div className="image-container">
            <img src={require(`../images/${card.image}`)} alt={card.name} />
            <img
              className="hover-image"
              src={require(`../images/${card.hoverImage}`)}
              alt={card.name}
            />
          </div>
          <div className="text-container">
            <h5 className="product-name">{card.name}</h5>
            <p className="price">
              <div>
                <p className="original-price">
                  {calculateOriginalPrice(card.price)}
                </p>
                <p className="discounted-price">{card.price}</p>
                <p className="saved-amount">
                  Save ${calculateSavedAmount(card.price)}
                </p>
              </div>
            </p>
          </div>
        </div>
      </Col>
    ));
  };

  return (
    <div className="new-arrival">
      <h2 className="text-center mt-5 mb-5 text-white">New Arrival</h2>
      <Carousel>
        <Carousel.Item>
          <Container>
            <Row className="justify-content-md-center">{renderCards(0, 4)}</Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container>
            <Row className="justify-content-md-center">{renderCards(4, 8)}</Row>
          </Container>
        </Carousel.Item>
        {/* Add more Carousel.Items if you have more sets of 4 cards */}
      </Carousel>
    </div>
  );
};

export default NewArrival;
